import BaseButton from '@/components/base/BaseButton'
import BaseButtonUp from '@/components/base/BaseButtonUp'
import Field from '@/components/form/field/Field'
import Button from '@/components/gui/button/Button'
import '@/helpers/metrics/yandex.js'
import CommonTemplate from '@/templates/common/Common'
import StepTemplate from '@/templates/step/Step'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'

store.dispatch('application/init')

Vue.config.productionTip = false

Vue.component('CommonTemplate', CommonTemplate)
Vue.component('StepTemplate', StepTemplate)


Vue.component('Button', Button)
Vue.component('Field', Field)
Vue.component('BaseButton', BaseButton)
Vue.component('BaseButtonUp', BaseButtonUp)

Vue.mixin({
    computed: {
        $DICTIONARY() {
            return this.$store.state.dictionary.dictionary
        }
    }
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
