var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questions__list"},[_c('div',{staticClass:"questions__item question",class:{
             'question_open': _vm.open === 1
         }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"question__title",on:{"click":function($event){return _vm.toggle(1)}}},[_vm._v(" Условия выдачи займа "),_c('span')])]),_c('VueSlideUpDown',{attrs:{"active":_vm.open === 1}},[_c('div',{staticClass:"question__content-wrapper"},[_c('div',{staticClass:"question__content"},[_c('h2',[_vm._v("Чтобы получить деньги, нужно совершить несколько простых действий")]),_c('ol',[_c('li',[_vm._v(" Зарегистрируйтесь на сайте проекта: для этого нужно нажать кнопку «Получить деньги». ")]),_c('li',[_vm._v(" Заполните анкету с необходимыми данными (понадобится паспорт). ")]),_c('li',[_vm._v(" Введите данные банковской карты и активируйте сервис. ")]),_c('li',[_vm._v(" Выберите самое интересное предложение и оформите займ. После короткого рассмотрения заявки на карту поступит зачисление. ")])])])])])],1),_c('div',{staticClass:"questions__item question",class:{
             'question_open': _vm.open === 2
         }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"question__title",on:{"click":function($event){return _vm.toggle(2)}}},[_vm._v(" Как долго рассматривается заявка? "),_c('span')])]),_c('VueSlideUpDown',{attrs:{"active":_vm.open === 2}},[_c('div',{staticClass:"question__content-wrapper"},[_c('div',{staticClass:"question__content"},[_c('ol',[_c('li',[_vm._v(" Часто обстоятельства складываются так, что деньги нужны здесь и сейчас. Мы понимаем это и относимся к вашему времени, как к своему: чтобы вы могли получить займ в любой момент, работаем 24/7. ")]),_c('li',[_vm._v(" Чтобы заполнить анкету, вам потребуется около 3 минут, рассмотрение заявки займет еще 5 минут, — и по истечении этого срока вы получите деньги удобным способом. ")])])])])])],1),_c('div',{staticClass:"questions__item question",class:{
             'question_open': _vm.open === 3
         }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"question__title",on:{"click":function($event){return _vm.toggle(3)}}},[_vm._v(" Если я не успеваю вернуть заем? "),_c('span')])]),_c('VueSlideUpDown',{attrs:{"active":_vm.open === 3}},[_c('div',{staticClass:"question__content-wrapper"},[_c('div',{staticClass:"question__content"},[_c('ol',[_c('li',[_vm._v(" Согласуйте рефинансирование вашего займа. Вам предложат погасить сумму на более лояльных условиях, которые помогут избежать больших штрафов за просрочку платежа. ")])])])])])],1),_c('div',{staticClass:"questions__item question",class:{
             'question_open': _vm.open === 4
         }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"question__title",on:{"click":function($event){return _vm.toggle(4)}}},[_vm._v(" Получу ли я деньги с плохой кредитной историей? "),_c('span')])]),_c('VueSlideUpDown',{attrs:{"active":_vm.open === 4}},[_c('div',{staticClass:"question__content-wrapper"},[_c('div',{staticClass:"question__content"},[_c('ol',[_c('li',[_vm._v(" Мы понимаем, что ситуации могут быть разными, и работаем со всеми клиентами: подберем для вас организации, которые готовы работать с заемщиками даже при наличии плохой кредитной истории. ")]),_c('li',[_vm._v(" Факт: если вы кредитуетесь в организации, которая входит в реестр Центрального банка, вовремя выплаченные займы помогают улучшить вашу кредитную историю. ")])])])])])],1),_c('div',{staticClass:"questions__item question",class:{
             'question_open': _vm.open === 5
         }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"question__title",on:{"click":function($event){return _vm.toggle(5)}}},[_vm._v(" Сколько я могу получить и на какой срок? "),_c('span')])]),_c('VueSlideUpDown',{attrs:{"active":_vm.open === 5}},[_c('div',{staticClass:"question__content-wrapper"},[_c('div',{staticClass:"question__content"},[_c('ol',[_c('li',[_vm._v(" У пользователя сервиса есть возможность получить займ до 100 000 рублей на срок до 1 года ")])])])])])],1),_c('div',{staticClass:"questions__item question",class:{
            'question_open': _vm.open === 6
        }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"question__title",on:{"click":function($event){return _vm.toggle(6)}}},[_vm._v(" Переплаты и комиссии по займу? "),_c('span')])]),_c('VueSlideUpDown',{attrs:{"active":_vm.open === 6}},[_c('div',{staticClass:"question__content-wrapper"},[_c('div',{staticClass:"question__content"},[_c('ol',[_c('li',[_vm._v(" Комиссия за выдачу займа составляет 0%. Обязательное страхование жизни и здоровья заемщика отсутствует. Переплата по займу составляет от 0 рублей и зависит от условий индивидуального кредитного предложения подобранного для клиента. ")])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }