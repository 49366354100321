<template>
    <Page class="loans-page">
        <template v-slot:title>
            Предложения
        </template>
        <div class="loans-page__desc">
            <span>Для гарантированного получения займа подайте заявки как минимум в 3 компании.</span>
        </div>
        <LoansList class="loans-page__list"/>
    </Page>
</template>

<script>
import LoansList from '@/applications/final/components/list/List'
import Page from '@/applications/personal-area/layouts/page/Page'
import Store from '@/store'
import Cookies from 'js-cookie'
import './loans-page.scss'

export default {
    name: 'Loans',
    components: {
        LoansList,
        Page,
    },
    beforeRouteEnter(to, from, next) {
        if (Cookies.get('sbg-cpa')) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'PersonalAreaProfile' })
        }
    }
}
</script>