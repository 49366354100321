<template>
    <div class="landing-view">
        <BigHeader>
            <template #title>
                <h1>Для получения займа под <span class="underline">0%</span> <span class="colored">заполните анкету</span></h1>
                <p>
                    Получи деньги <span>быстро</span> и <span>просто</span>
                    независимо от вашей
                    кредитной истории
                </p>
                <div class="button">
                    <router-link to="/primary" class="d-flex align-items-center">
                        <span>Заполнить заявку</span>
                    </router-link>
                </div>
            </template>
        </BigHeader>

        <section-wrapper title="Как это работает?" class="steps observed">
            <steps-list></steps-list>
        </section-wrapper>

        <section-wrapper title="Способы получения займа в МФО" class="pays observed">
            <pay-list></pay-list>
        </section-wrapper>

        <section-wrapper title="О нас" class="info observed">
            <About />
        </section-wrapper>

        <section-wrapper title="Выберите свой займ" class="offers observed">
            <offers-list></offers-list>
        </section-wrapper>

        <section-wrapper title="Отзывы наших клиентов" class="reviews observed">
            <reviews-list></reviews-list>
        </section-wrapper>

        <section-wrapper title="Часто спрашивают" class="questions observed">
            <Questions/>
        </section-wrapper>

        <section-wrapper title="Мы помогаем получить деньги" class="stat observed">
            <stat-list></stat-list>
        </section-wrapper>

        <section-wrapper title="Контактная информация" class="contacts observed" v-if="!isMobile">
            <contacts-list></contacts-list>
        </section-wrapper>

        <Footer />
        <base-button-up></base-button-up>
    </div>
</template>

<script>
import Cookies from 'js-cookie'

import SectionWrapper from '@/applications/landing/components/SectionWrapper'
import BigHeader from '@/components/common/big-header/BigHeader'

import Store from '@/store'

import comebacker from '@/mixins/comebacker'
import mobileMixin from '@/mixins/mobile'

export default {
    name: 'App',
    mixins: [
        comebacker,
        mobileMixin
    ],
    components: {
        BigHeader,
        SectionWrapper,
        About: () => import('./components/about/About'),
        StepsList: () => import('./components/steps/StepsList'),
        PayList: () => import('./components/pay/PayList'),
        OffersList: () => import('./components/offers/OffersList'),
        ReviewsList: () => import('./components/reviews/ReviewsList'),
        StatList: () => import('./components/stat/StatList'),
        Questions: () => import('./components/questions/Questions'),
        ContactsList: () => import('./components/contacts/ContactsList'),
        Footer: () => import('@/components/common/footer/Footer'),
    },
    methods: {
        setObserver() {
            const allSections = this.$el.querySelectorAll('.observed');

            const revealSection = function (entries, observer) {
                const [entry] = entries;

                if (!entry.isIntersecting) return;

                entry.target.classList.remove('observed--hidden');
                observer.unobserve(entry.target);
            };

            const sectionObserver = new IntersectionObserver(revealSection, {
                root: null,
                threshold: 0.15,
            });

            allSections.forEach(function (section) {
                sectionObserver.observe(section);
                section.classList.add('observed--hidden');
            });
        }
    },
    mounted() {
        this.setObserver();
    },
    beforeRouteEnter(to, from, next) {
        if (Cookies.get('sbg-cpa')) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'LoanPrimary' })
        }
    }
};
</script>

<style lang="scss">
.observed {
  transition: transform 1s, opacity 1s;
}
.observed--hidden {
  opacity: 0;
  transform: translateY(8rem);
}
@media(max-width: $mobile) {
  .observed--hidden {
    opacity: 0;
    transform: translateY(6rem);
  }
}
</style>